import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from 'rebass/styled-components';

import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import GhostLink from '../components/Buttons/GhostLink';

const Service = (props) => {
  const { data } = props;
  const { title: siteTitle } = data.site.siteMetadata;
  const {
    title, content, featured_media: image, acf,
  } = data.wordpressWpMphbRoomService;
  const { price, gallery } = acf;

  return (
    <Layout>
      <Helmet title={`${title} | ${siteTitle}`} />
      <Section>
        <Container narrow>
          {image && <Img fluid={image.localFile.childImageSharp.fluid} alt={title} />}

          <h1>{title}</h1>

          {price && <p><small>{`Cena: ${price}`}</small></p>}

          <div dangerouslySetInnerHTML={{ __html: content }} />

          {gallery && gallery.map(photo => (
            <Box mb={2} key={photo.localFile.id}>
              <Img loading="lazy" fadeIn={false} fluid={photo.localFile.childImageSharp.fluid} alt={photo.alt_text} />
            </Box>
          ))}

          <GhostLink to="/nase-sluzby/">← Zpět na všechny služby</GhostLink>
        </Container>
      </Section>
    </Layout>
  );
};

export default Service;

export const pageQuery = graphql`
  query ServicePage($slug: String!) {
    wordpressWpMphbRoomService(slug: { eq: $slug }) {
      id
      title
      content
      slug
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 360) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        price
        gallery {
          alt_text
          localFile {
            name
            id
            childImageSharp {
              fluid(maxWidth: 1280, maxHeight: 720) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
